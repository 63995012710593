// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-templates-events-event-post-tsx": () => import("./../src/templates/events/EventPost.tsx" /* webpackChunkName: "component---src-templates-events-event-post-tsx" */),
  "component---src-templates-events-events-page-tsx": () => import("./../src/templates/events/EventsPage.tsx" /* webpackChunkName: "component---src-templates-events-events-page-tsx" */),
  "component---src-templates-updates-update-post-tsx": () => import("./../src/templates/updates/UpdatePost.tsx" /* webpackChunkName: "component---src-templates-updates-update-post-tsx" */),
  "component---src-templates-updates-updates-page-tsx": () => import("./../src/templates/updates/UpdatesPage.tsx" /* webpackChunkName: "component---src-templates-updates-updates-page-tsx" */)
}

