import React, { createContext, ReactElement } from 'react';

let items: string | 0 | null = 0;
const windowGlobal: any = typeof window !== 'undefined' && window;

if (windowGlobal) {
  items = localStorage.getItem('ItemsInBag')
    ? localStorage.getItem('ItemsInBag')
    : 0;
}

const initialState = {
  cartCount: items ? JSON.parse(items).length : 0,
  imageModalOpen: false,
  imageModalImageSrc:
    '/static/70a0355434aa7c6c5ed824673f132ebd/f422e/event-1.jpg',
  activePage: '',
};

export const SiteContext = createContext({
  globalState: initialState,
  dispatch: (value: any) => value,
});
export const SiteContextConsumer = SiteContext.Consumer;

const reducer = (state: any, action: { payload: any; type: any }) => {
  const { payload, type } = action;
  switch (type) {
    case 'SET_CART_COUNT':
      return {
        ...state,
        cartCount: payload,
      };
    case 'SET_IMAGE_MODAL_OPEN':
      return {
        ...state,
        imageModalOpen: payload,
      };
    case 'SET_IMAGE_MODAL_SRC':
      return {
        ...state,
        imageModalImageSrc: payload,
      };
    case 'SET_ACTIVE_PAGE':
      return {
        ...state,
        activePage: payload,
      };
    default:
      return state;
  }
};

interface Props {
  children: ReactElement;
}
export const SiteContextProvider = ({ children }: Props) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  return (
    <SiteContext.Provider value={{ globalState, dispatch }}>
      {children}
    </SiteContext.Provider>
  );
};
