/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { SiteContextProvider } from './src/state';

import React from 'react';
import { ToastProvider } from '@adamwebster/fused-components';

config.autoAddCss = false;

export const wrapRootElement = ({ element }) => (
  <SiteContextProvider>
    <ToastProvider position="top">{element}</ToastProvider>
  </SiteContextProvider>
);
